<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="container vert-offset-top-1">
        <div class="row justify-content-center">
            <div class="col s12 m10 l8 offset-m1 offset-l2">

                <div class="card col s12 vert-offset-top-1 padding-top-half">

                    <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                            <router-link class="btn-small homeboy-charcoal-black white-text margin-right-half" to="/customer/home">
                                <i class="material-icons">arrow_back</i>
                            </router-link>
                        <span>
                            Edit Your Profile
                        </span>
                    </div>
                    <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

                        <div class="row">
                            <div class="col s12">
                                <form @submit.prevent="confirmCustomerUpdate">

                                    <div class="row vert-offset-bottom-half">

                                        <div class="col s12">
                                            <input-component label="Name" id="name" type="text"  v-model="name"  ref="name"></input-component>
                                        </div>
                                    </div>

                                    <div class="row vert-offset-bottom-half">

                                        <div class="col s12">
                                            <input-component label="ID Number" id="idNumber" type="text"  v-model="idNumber" ref="idNumber"></input-component>
                                        </div>
                                    </div>

                                    <div class="row vert-offset-bottom-half">

                                        <div class="col s12">
                                            <input-component id="email" type="email" v-model="email" label="E-Mail Address" ref="email"></input-component>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col s12">
                                            <select-component id="gender"  ref="gender" v-model="selected_gender" :options="genders" >Select Gender</select-component>
                                        </div>

                                        <div class="col s12">
                                            <select-component id="yob"  ref="yob" v-model="selected_yob" :options="yobs" >Select Year Of Birth</select-component>
                                        </div>

                                    </div>

                                    <div class="row vert-offset-bottom-half">
                                        <div class="col s12 center">
                                            <button type="submit" class="btn homeboy-gold-text homeboy-charcoal-black" click.prevent="confirmCustomerUpdate">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import http from "../../http-common"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import SelectComponent from "@/components/materialize/SelectComponent.vue"
    import { bus } from "../../main"
    import {mapState, mapGetters} from "vuex"



    export default {
        name: 'CustomerUpdate',
        components:{
           InputComponent,SelectComponent
        },
        data(){
            return{
                name:"",
                idNumber:"",
                email:"",
                selected_gender:"",
                selected_yob:""
            }
        },
        created(){
            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if(_data == "confirmUpdateCustomer")
                {
                    console.log("Trigger update customer.");
                    this.updateCustomer();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
        },

        mounted(){
            console.log("ready beforeMount");

            this.idNumber = this.customer.idnumber;
            this.email = this.customer.email;
            this.name = this.customer.name;

            var $this = this;
            setTimeout(function(){

                $this.$refs.name.refreshInput();

            },500);




            if(this.customer.gender !== null)
            {
                console.log("Gender != null:");
                this.selected_gender = this.customer.gender;
                this.$refs.gender.initializeSelect();
            }
            if(this.customer.yob !== null)
            {
                console.log("Yob != null:");
                this.selected_yob = this.customer.yob;
                this.$refs.yob.initializeSelect();
            }
        },

        methods:{
            updateCustomer()
            {
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: 'l2l/customers/update',
                        method: 'POST',
                        data: {
                            name:this.name,
                            email:this.email,
                            idnumber:this.idNumber,
                            gender:this.selected_gender,
                            yob:this.selected_yob,
                            customer:this.customer.key,
                            source: this.$source

                        }, timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            this.$store.dispatch("GET_MESSAGES",["Update successful!"]);

                            console.log("New Customer: "+resp.data.data);
                            this.$store.dispatch("SET_CUSTOMER",resp.data.data);

                            this.resetForm();

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response.data));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })

            },
            confirmCustomerUpdate: function()
            {
                console.log("Event Triggered: confirmUpdateCustomer");
                if(this.idNumber !== "" || this.name !== "" || this.email !== "")
                {
                    this.$store.dispatch('SET_ACTION', 'confirmUpdateCustomer');
                }
            },
            resetForm:function()
            {
                this.name="";
                this.idNumber = "";
                this.email="";
                this.selected_gender = "";
                this.selected_yob = "";

            }
        },
        computed:{
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user
            }),

            ...mapGetters({
                genders:'gender',
                yobs:'year_of_birth',
            })
        },
    }
</script>
